<template>
  <!-- Landing page-->
  <div>
    <div class="misc-wrapper background-no-repeat position-relative" :style=" 'background-image: url(' + imgUrl + ');'">

      <b-link class="brand-logo">
        <vuexy-logo/>
        <b-img class="ml-1" src="@/assets/images/logo/logo-2.png" fluid alt="Logo Text" style="height: 40px"/>
      </b-link>

      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2>
            <a style="color: #ffffff" class="typewrite h2" data-period="2000"
               data-type='[ "Dotnet", "Furry", "Networking", "Videography", "Floof.ch | BetaFloof" ]'>
              <span class="wrap"></span>
            </a>
          </h2>
          <h4 style="color: #FFFFFF">A testing ground for my projects for you to try. <-- That's just not good</h4>
          <b-button
              class="login-button"
              variant="primary"
              href="/login"
          >
            Login
          </b-button>
        </div>
      </div>
    </div>
    <div class="ml-3 mr-3">
      <br/>

      <b-card no-body>
        <b-card-header class="pb-1">
          <h1>Projects</h1>
        </b-card-header>
      </b-card>

      <b-row class="match-height">
        <b-col lg="2" cols="6">
          <card-statistic-order-chart/>
        </b-col>
      </b-row>
    </div>
    <app-footer class="ml-2 mr-2 mb-1"/>
  </div>
</template>
<!-- / Landing page-->

<script>

const isLoggedIn = isUserLoggedIn()



var TxtType = function (el, toRotate, period) {
  this.toRotate = toRotate;
  this.el = el;
  this.loopNum = 0;
  this.period = parseInt(period, 10) || 2000;
  this.txt = '';
  this.tick();
  this.isDeleting = false;
};

TxtType.prototype.tick = function () {
  var i = this.loopNum % this.toRotate.length;
  var fullTxt = this.toRotate[i];

  if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
  } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
  }

  this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

  var that = this;
  var delta = 200 - Math.random() * 100;

  if (this.isDeleting) {
    delta /= 2;
  }

  if (!this.isDeleting && this.txt === fullTxt) {
    delta = this.period;
    this.isDeleting = true;
  } else if (this.isDeleting && this.txt === '') {
    this.isDeleting = false;
    this.loopNum++;
    delta = 500;
  }

  setTimeout(function () {
    that.tick();
  }, delta);
};

window.onload = function () {
  console.log("LOAD")
  var elements = document.getElementsByClassName('typewrite');
  for (var i = 0; i < elements.length; i++) {
    var toRotate = elements[i].getAttribute('data-type');
    var period = elements[i].getAttribute('data-period');
    if (toRotate) {
      new TxtType(elements[i], JSON.parse(toRotate), period);
    }
  }

  // INJECT CSS
  var css = document.createElement("style");
  css.type = "text/css";
  css.innerHTML = ".typewrite > .wrap { border-right: 0.10em solid #07FF18}";
  document.body.appendChild(css);

  var loginButtons = document.getElementsByClassName('login-button');
  if (isUserLoggedIn()) {
    // ToDo: alter icon
    loginButtons
  }
  else {

  }

};

/* eslint-disable global-require */
import {
  BLink, BForm, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import AppFooter from '@core/layouts/components/AppFooter.vue'

import {BRow, BCol, BCard, BCardHeader, BCardBody} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import {kFormatter} from '@core/utils/filter'
import CardStatisticOrderChart from './CardStatisticOrderChart.vue'
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import {getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn} from '@/auth/utils'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    VuexyLogo,
    AppFooter,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BImg,
    BRow,
    BCol,
    StatisticCardVertical,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
    CardStatisticOrderChart,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/landing-bg-1920x1080.jpg'),
    }
  },
  mounted(){
    window.addEventListener("load", () => this.onWindowLoad());
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/landing-bg-1920x1080.jpg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    onWindowLoad() {

    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
